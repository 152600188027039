import React from 'react'
import { usePutRequest } from '../hooks/request'
import { ORDER_DETAIL } from '../urls'
import CheckFormCustom from './CheckFormCustom'

export default function CheckUpdateCustom({
    reload,
    item,
}) {
    const productUpdate = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await productUpdate.request({
            data: {
                ...data,
                items: data.orderProduct,
                client: data.client ? data.client.id : undefined,
            },
        })
        if (success) {
            reload.request()
            // onCancel()
        }
    }

    return (
        <div>
            <CheckFormCustom
                initialValues={item}
                loading={productUpdate.loading}
                onSubmit={onSubmit} />
        </div>
    )
}

import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'querystring'
import cn from 'classnames'
import Layout from '../components/Layout/Layout'
import { useLoad } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ProductCreate from '../components/ProductCreate'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Table from '../components/common/Table'
import ProductItem from '../components/ProductItem'
import CheckCreate from '../components/CheckCreate'
import CheckItem from '../components/CheckItem'
import Loader from '../components/common/Loader'
import { PermissionDenied } from '../components/PermissionDenied'
import { checkPermission as cp } from '../utils/auth'
import AdminTabs from '../components/AdminTabs'

export default function Products() {
    const params = useQueryParams()
    const history = useHistory()
    const isMobile = window.innerWidth <= 500
    const [page, setPage] = useState(1)
    const products = useLoad({
        url: PRODUCT_LIST,
        params: { page, ...params },
    }, [params, page])
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })
    const checkItem = check.response ? check.response.results : []
    const [showCreateModal, setShowCreateModal] = useModal(
        <ProductCreate
            reload={products}
            onCancel={() => setShowCreateModal()}
        />,
    )
    const [showCreateCheck, hideCreateCheck] = useModal(
        <CheckCreate
            reload={check}
            onCancel={() => hideCreateCheck()}
        />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    if (!cp('READ')) {
        return <PermissionDenied />
    }
    return (
        <Layout onSearch={onSearch} showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            {isMobile ? <AdminTabs /> : null}
            <div className="is-flex columns">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (
                    <div className={cn(css(styles.table), 'column is-7')}>
                        <div className={cn(css(styles.tableItem), 'box')}>
                            <Table
                                loading={products.loading}
                                items={productList}
                                columns={{
                                    name: 'Название',
                                    price: 'Цена',
                                    registered_at: 'Измерение ',
                                    actions: '',
                                }}
                                totalCount={products.response ? products.response.count : 0}
                                pageSize={15}
                                activePage={page}
                                onPageChange={setPage}
                                renderItem={(item) => (
                                    <ProductItem
                                        reload={products}
                                        products={products}
                                        check={check}
                                        key={item.id}
                                        item={item}
                                    />
                                )} />
                        </div>
                    </div>
                )}

                {!isMobile ? (
                    <>
                        {!check.loading ? (
                            <div className="is-5 column">
                                {check.response && check.response.count === 0 ? (
                                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                                    </div>
                                ) : (
                                    <div className="">
                                        {checkItem.map((item) => (
                                            <CheckItem
                                                check={check}
                                                reload={check}
                                                key={item.id}
                                                item={item}
                                            />
                                        ))}
                                    </div>
                                )}
                                <div className=" is-flex is-justify-content-center" />
                            </div>
                        ) : (
                            <Loader className={css(styles.loader)} large center />
                        )}
                    </>
                ) : null}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        height: 400,
    },
    table: {
        marginBottom: '2rem', // Add margin bottom to provide space
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    tableItem: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
    br: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
    firstMobile: {
        '@media (max-width: 500px)': {
            order: 1,
            height: '1000px !important',
            width: 800,
        },
    },
    secondMobile: {
        '@media (max-width: 500px)': {
            order: 2,
            height: '1000px',
            width: 800,
        },
    },
    flexContainer: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'row',
        '@media (max-width: 500px)': {
            flexDirection: 'column',
        },
    },
})

import React from 'react'
import Button from './common/Button'
import { ORDER_PRODUCT_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { format } from '../utils/number'

export default function ClientCheckProductItem({
    reload,
    element,
}) {
    const orderProductRemove = useDeleteRequest({ url: ORDER_PRODUCT_DETAIL.replace('{id}', element.id) })

    async function onDeleteOrderProduct() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await orderProductRemove.request()
            await reload.request()
        }
    }
    return (

        <tr style={{ textAlign: 'center' }} key={element.id} className="">
            <td style={{ width: 120 }} className="">{element.product.name}</td>
            <td style={{ width: 50 }} className="">{element.count} </td>
            <td className="">{element.product.priceSale}</td>
            <td className="">{format(element.product.priceSale * element.count)}</td>
            <td style={{ textAlign: 'center' }} className="">
                <Button
                    onClick={onDeleteOrderProduct}
                    className="button is-danger is-pulled-center is-outlined is-small"
                    icon="trash" />

            </td>
        </tr>

    )
}

import React from 'react'
import { Field } from 'formik'

export default function Checkbox({
    name,
    className,
    label,
    validate,
    ...attributes
}) {
    return (
        <div>
            <Field type="checkbox" name={name} className={className} validate={validate} {...attributes} id={name} />
        </div>
    )
}

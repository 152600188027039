import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { userTypes } from '../utils/types'

export default function UserForm({ onCancel, onSubmit, initialValues = {} }) {
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                username: '',
                phoneNumber: '',
                userType: '',
                ...initialValues,
            }}>
            <Form>
                <Input
                    name="username"
                    label="Имя"
                    validate={required} />

                <Input
                    name="phoneNumber"
                    label="Номер телефона"
                    validate={required} />

                <Select
                    empty
                    optionValue="value"
                    optionLabel="name"
                    name="userType"
                    label="Тип пользователя"
                    options={userTypes} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="close-outline"
                        text="Отмена"
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        text="Сохранить"
                        icon="add-outline"
                        type="submit"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}

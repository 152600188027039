export const types = [{ value: 'outcome', label: 'Расход' }, { value: 'income', label: 'Приход' }]

export const measurements = [
    {
        name: 'шт',
        value: 'pieces',
    },
    {
        name: 'литр',
        value: 'litres',
    },
    {
        name: 'блок',
        value: 'stock',
    },
    {
        name: 'кг',
        value: 'kg',
    },
]

export function getMeasurement(value) {
    return measurements.find((item) => item.value === value).name
}

export const userTypes = [
    {
        name: 'администратор',
        value: 'admin',
    },
    {
        name: 'клиент',
        value: 'customer',
    },
]
export const userTypesSecond = [
    {
        name: 'администратор',
        value: 'admin',
    },
    {
        name: 'администратор',
        value: 'ADMIN',
    },
    {
        name: 'клиент',
        value: 'customer',
    },
]

export function getUserType(value) {
    return userTypesSecond.find((item) => item.value === value).name
}

export const statuses = [
    {
        name: 'Новый',
        value: 'new',
    },
    {
        name: 'Принят',
        value: 'accepted',
    },
    {
        name: 'Доставлен',
        value: 'delivered',
    },
    {
        name: 'Отменён',
        value: 'canceled',
    },
]

import React from 'react'
import Button from './common/Button'
import { CHECK_PASSWORD, PRODUCT_DETAIL } from '../urls'
import { useDeleteRequest, usePostRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import ProductUpdate from './ProductUpdate'
import ProductDetailModal from './ProductDetailModal'
import { format } from '../utils/number'
import { measurement } from '../utils/position'

export default function ProductItem({
    reload,
    check,
    categoryList,
    item,
    user,
    brandList,
    products,
}) {
    const [showDetailModal, hideDetailModal] = useModal(
        <ProductDetailModal
            check={check}
            orderId={item.id}
            user={user}
            item={item}
            hideModal={() => hideDetailModal()}
        />,
    )

    const productRemove = useDeleteRequest({ url: PRODUCT_DETAIL.replace('{id}', item.id) })
    const checkPassword = usePostRequest({ url: CHECK_PASSWORD })
    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const password = global.prompt('Enter password to proceed:', '')
            try {
                // Send password to the backend to validate
                const { success } = await checkPassword.request({ data: { password } })

                // If the backend validates the password
                if (success) {
                    await productRemove.request()
                    await reload.request()
                } else {
                    global.alert('Incorrect password. Deletion aborted.')
                }
            } catch (error) {
                global.alert('An error occurred. Deletion aborted.')
            }
        }
    }
    const [showUpdateModal, setShowUpdateModal] = useModal(
        <ProductUpdate
            products={products}
            categoryList={categoryList}
            brandList={brandList}
            item={item}
            hideModal={() => setShowUpdateModal()}
        />,
    )

    return (
        <tr className="is-capitalized">
            <td onClick={() => showDetailModal()} className="is-size-5">{item.name}</td>
            <td className="is-size-5">{format(item.priceSale)} {/* <h5 className="is-size-6">uzs</h5> */}</td>
            <td className="is-size-5">{measurement(item.measurement)}</td>
            <td className="has-text-right">
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 button is-success"
                    icon="pencil-outline" />
                <Button
                    onClick={onDelete}
                    className="button is-danger"
                    icon="trash" />

            </td>
        </tr>
    )
}

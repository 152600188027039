import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Button from './common/Button'
import { useDeleteRequest, usePostRequest } from '../hooks/request'
import { CHECK_PASSWORD, ORDER_DETAIL } from '../urls'
import { useModal } from '../hooks/modal'
import ViewCheckArchive from './ViewCheckArchive'
import { getDateTime } from '../utils/date'
import ComponentToPrint from './Print'
import { format } from '../utils/number'

export default function ArchiveItem({
    item,
    ordersList,
}) {
    const orderRemove = useDeleteRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })
    // eslint-disable-next-line max-len,no-return-assign
    const resultArchive = item.orderHistory.reduce((total, currentValue) => total + parseInt(currentValue?.total, 10), 0)
    const checkPassword = usePostRequest({ url: CHECK_PASSWORD })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const password = global.prompt('Enter password to proceed:', '')
            try {
                const { success } = await checkPassword.request({ data: { password } })
                if (success) {
                    await orderRemove.request()
                    await ordersList.request()
                } else {
                    global.alert('Incorrect password. Deletion aborted.')
                }
            } catch (error) {
                global.alert('An error occurred. Deletion aborted.')
            }
        }
    }

    const [showViewModal, setShowViewModal] = useModal(
        <ViewCheckArchive
            result={resultArchive}
            item={item}
            hideModal={() => setShowViewModal()}
        />,
    )
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    return (
        <tr key={item.id}>
            <td onClick={showViewModal} className="is-size-5">{item.client.name}</td>
            <td className="is-size-5">{item.client.phoneNumber}</td>
            <td className="is-size-5">{format(resultArchive)}</td>
            <td className="is-size-5">{getDateTime(item.createdAt)}</td>
            <td className="has-text-right">
                <div className="is-flex">
                    <Button
                        onClick={handlePrint}
                        className="button is-link mr-2"
                        icon="print" />
                    <div style={{ display: 'none' }}>
                        <ComponentToPrint result={resultArchive} item={item} ref={componentRef} />
                    </div>
                    <Button
                        onClick={onDelete}
                        className="button is-danger"
                        icon="trash" />
                </div>
            </td>
        </tr>
    )
}

import React from 'react'
import { Form, Formik } from 'formik'
import { Redirect, useHistory } from 'react-router-dom'
import Button from '../components/common/Button'
import Input from '../components/common/Input'
import ServerError from '../components/common/ServerError'
import { usePostRequest } from '../hooks/request'
import { phone, required, validator } from '../utils/validators'
import Password from '../components/common/Password'
import { isAuthenticated, signin } from '../utils/auth'
import LayoutAuth from '../components/LayoutAuth'
import LoginTabs from '../components/LoginTabs'

export default function LoginClient() {
    const history = useHistory()
    const signIn = usePostRequest({ url: '/users/sign-in-phone' })
    if (isAuthenticated()) {
        return <Redirect to="/client" />
    }

    async function onSubmit(data) {
        const {
            response,
            success,
        } = await signIn.request({ data })
        if (success) {
            signin(response, history)
        }
    }

    return (
        <LayoutAuth sidebar={(
            <div>
                <div className="mb-3">
                    <h2 className="is-size-3 has-text-weight-bold">Добро пожаловать!</h2>
                </div>
            </div>
        )}>

            <div id="login-title-panel" className="has-text-centered mb-4">
                <h2 className="is-size-4 has-text-weight-bold mb-4">Войдите в систему</h2>
            </div>

            <LoginTabs />
            {/* eslint-disable-next-line react/jsx-no-bind */}

            <Formik onSubmit={onSubmit} initialValues={{
                phoneNumber: '',
                confirmationCode: '',
            }}>
                <Form>
                    <ServerError error={signIn.error} />
                    <Input name="phoneNumber" validate={validator(required, phone)} placeholder="Номер телефона" />
                    <Password name="confirmationCode" validate={required} placeholder="Код подтверждения" />

                    <div className="field">
                        <div className="control">
                            <Button
                                loading={signIn.loading}
                                className="is-primary is-blue is-medium is-fullwidth"
                                text="Войти"
                                type="submit" />
                        </div>
                    </div>

                </Form>
            </Formik>
        </LayoutAuth>
    )
}

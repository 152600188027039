import React from 'react'
import Button from './common/Button'
import { BORROW_DETAIL, CHECK_PASSWORD, ORDER_DETAIL } from '../urls'
import { useDeleteRequest, useLoad, usePostRequest } from '../hooks/request'
import BorrowUpdate from './BorrowUpdate'
import { getDateOtherFormat, getDateTime } from '../utils/date'
import { format } from '../utils/number'
import { useCustomModal } from '../hooks/customModal'
// import Input from './common/Input'
import ViewCheckArchive from './ViewCheckArchive'

export default function ClientBorrowItem({
    reload,
    item,
    borrows,
    index,
}) {
    const clientBorrowRemove = useDeleteRequest({ url: BORROW_DETAIL.replace('{id}', 82) })

    const checkPassword = usePostRequest({ url: CHECK_PASSWORD })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            const password = global.prompt('Enter password to proceed:', '')
            try {
                // Send password to the backend to validate
                const { success } = await checkPassword.request({ data: { password } })

                // If the backend validates the password
                if (success) {
                    await clientBorrowRemove.request()
                    await borrows.request()
                } else {
                    global.alert('Incorrect password. Deletion aborted.')
                }
            } catch (error) {
                global.alert('An error occurred. Deletion aborted.')
            }
        }
    }

    const [showUpdateModal, hideUpdateModal] = useCustomModal(
        <BorrowUpdate
            borrows={borrows}
            reload={reload}
            item={item}
            hideModal={() => hideUpdateModal()}
        />,
    )

    const currentDate = new Date()
    const orderDetail = useLoad({ url: ORDER_DETAIL.replace('{id}', item.order) })
    const test = orderDetail.response ? orderDetail.response : {}
    /* const ele = orderDetail.response
        ? orderDetail.response.reduce((total, currentValue) => total + parseInt(currentValue?.total, 10), 0)
        : 0
    console.log(ele) */
    const [showViewModal, setShowViewModal] = useCustomModal(
        <ViewCheckArchive
            result={1000}
            item={test}
            hideModal={() => setShowViewModal()}
        />,
    )
    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger' : 'has-text-black'}`}
            key={item.id}>
            <td className="is-size-5">{index + 1}</td>
            <td className="is-size-5">{format(item.amount)}</td>
            <td className="is-size-5">{item.description}</td>
            <td className="is-size-5">{getDateTime(item.createdAt)}</td>
            <td className="is-size-5">{item.termDate}</td>

            <td className="mt-2">
                <div style={{ display: 'flex' }}>
                    <Button
                        onClick={showUpdateModal}
                        className="mr-2 is-success"
                        icon="pencil-outline"
                    />
                    <Button
                        onClick={onDelete}
                        className="is-danger mr-2"
                        icon="trash"
                    />

                    <Button
                        onClick={showViewModal}
                        className="button is-link mr-2"
                        icon="print" />
                </div>
            </td>

        </tr>

    )
}

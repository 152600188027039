import { isEmpty } from 'lodash'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { checkPermission as cp, signOut } from '../../utils/auth'
import Search from '../Search'
import Button from '../common/Button'
import { useGetRequest } from '../../hooks/request'
import { ALL_SEND_MESSAGE, CLIENT_EXPORT, domain } from '../../urls'
import { useMessage } from '../../hooks/message'

export default function Layout({ children, padding = true, className, onSearch, showCreateModal, showCreateCheck, onSend: onSendSelect }) {
    const history = useHistory()
    const { path } = useRouteMatch()
    const [menu, setMenu] = useState(false)
    const user = JSON.parse(localStorage.user)

    async function logOut() {
        if (global.confirm('Вы действительно хотите выйти из системы ?')) {
            await signOut(history)
        }
    }

    const sendMessage = useGetRequest({ url: ALL_SEND_MESSAGE })
    const [showMessage] = useMessage()

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage('Сизнинг sms хабарингиз  муваффақиятли юборилди', 'is-success')
        }
    }

    const excel = useGetRequest({ url: CLIENT_EXPORT })
    const excelItem = excel.response ? excel.response.fileUrl || excel.response.file : null

    useEffect(() => {
        if (excelItem && !isEmpty(excelItem)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItem}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItem])

    return (
        <div>
            <div>
                {user.admin ? (
                    <Fragment>
                        <div className={css(styles.sidebar, menu ? styles.desktopSidebar : styles.mobileSidebar)}>
                            <ul className={css(styles.sideMenu)}>

                                <NavLink to="/categories" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        {
                                            [css(styles.active)]:
                                            path.startsWith('/categories') || path.startsWith('/category'),
                                        },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="list-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Категории
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/products" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/products') },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="fast-food-outline" />
                                        </span>
                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Товары
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/territory" exact>
                                    <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/territory') })}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="globe-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Территории
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/brands" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/brands') },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="business-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Фирмы
                                        </span>
                                    </li>
                                </NavLink>

                                <li
                                    className={cn('columns is-mobile is-gapless is-clickable', css(styles.link))}
                                    onClick={logOut}
                                >
                                    <span className={cn('column is-narrow', css(styles.icon))}>
                                        <ion-icon name="log-out-outline" />
                                    </span>

                                    <span className={cn('column is-narrow', css(styles.text))}>
                                        Выйти
                                    </span>
                                </li>

                            </ul>
                        </div>
                        <div className={cn(css(styles.container))}>
                            <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                                <span onClick={() => setMenu(true)} className={css(styles.showerIcon)}>
                                    <ion-icon size="large" name="menu-outline" />
                                </span>
                                {children}
                            </div>
                        </div>

                        <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
                    </Fragment>
                ) : (
                    <Fragment>
                        {/* eslint-disable-next-line max-len */}
                        <div
                            className={css(styles.marketSidebar, menu
                                ? styles.marketDesktopSidebar : styles.marketMobileSidebar)}>
                            <ul className={css(styles.sideMenu)}>

                                {cp('READ') ? (
                                    <NavLink to="/borrow" exact>
                                        <li className={cn(
                                            'columns is-mobile is-gapless',
                                            css(styles.link),
                                            {
                                                [css(styles.active)]:
                                                path.startsWith('/borrow') || path.startsWith('/borrow'),
                                            },
                                        )}>
                                            <span className={cn('column is-narrow', css(styles.icon))}>
                                                <ion-icon name="list-outline" />
                                            </span>
                                            <span className={cn('column is-narrow', css(styles.text))}>
                                                Долги
                                            </span>
                                        </li>
                                    </NavLink>
                                ) : null}
                                {cp('READ') ? (
                                    <NavLink to="/users" exact>
                                        <li className={cn(
                                            'columns is-mobile is-gapless',
                                            css(styles.link),
                                            {
                                                [css(styles.active)]:
                                                path.startsWith('/users') || path.startsWith('/users'),
                                            },
                                        )}>
                                            <span className={cn('column is-narrow', css(styles.icon))}>
                                                <ion-icon name="person-add-outline" />
                                            </span>
                                            <span className={cn('column is-narrow', css(styles.text))}>
                                                Пользователи
                                            </span>
                                        </li>
                                    </NavLink>
                                ) : null}
                                {cp('READ_CLIENT') ? (
                                    <NavLink to="/client" exact>
                                        <li className={cn(
                                            'columns is-mobile is-gapless',
                                            css(styles.link),
                                            {
                                                [css(styles.active)]:
                                                path.startsWith('/client'),
                                            },
                                        )}>
                                            <span className={cn('column is-narrow', css(styles.icon))}>
                                                <ion-icon name="list-outline" />
                                            </span>
                                            <span className={cn('column is-narrow', css(styles.text))}>
                                                Заказы
                                            </span>
                                        </li>
                                    </NavLink>
                                ) : null}

                                {/* <NavLink to="/brands" exact>
                                    <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/brands') })}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="business-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Фирмы
                                        </span>
                                    </li>
                                </NavLink> */}

                                {cp('READ') ? (
                                    <NavLink to="/products" exact>
                                        <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                            { [css(styles.active)]: path.startsWith('/products') })}>
                                            <span className={cn('column is-narrow', css(styles.icon))}>
                                                <ion-icon name="fast-food-outline" />
                                            </span>

                                            <span className={cn('column is-narrow', css(styles.text))}>
                                                Товары
                                            </span>
                                        </li>
                                    </NavLink>
                                ) : null}
                                {cp('READ') ? (
                                    <NavLink to="/cart" exact>
                                        <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                            { [css(styles.active)]: path.startsWith('/cart') })}>
                                            <span className={cn('column is-narrow', css(styles.icon))}>
                                                <ion-icon name="cart-outline" />
                                            </span>
                                            <span className={cn('column is-narrow', css(styles.text))}>
                                                Корзина
                                            </span>
                                        </li>
                                    </NavLink>
                                ) : null}
                                {cp('READ') ? (
                                    <NavLink to="/settings/user" exact>
                                        <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                            { [css(styles.active)]: path.startsWith('/settings/user') })}>
                                            <span className={cn('column is-narrow', css(styles.icon))}>
                                                <ion-icon name="settings-outline" />
                                            </span>
                                            <span className={cn('column is-narrow', css(styles.text))}>
                                                Настройки
                                            </span>
                                        </li>
                                    </NavLink>
                                ) : null}

                                <li
                                    className={cn('columns is-mobile is-gapless is-clickable', css(styles.link))}
                                    onClick={logOut}
                                >
                                    <span className={cn('column is-narrow', css(styles.icon))}>
                                        <ion-icon name="log-out-outline" />
                                    </span>

                                    <span className={cn('column is-narrow', css(styles.text))}>
                                        Выйти
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className={cn(css(styles.marketContainer))}>
                            <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                                <span className={css(styles.marketShowerIcon)}>

                                    <div
                                        onClick={() => history.push('/cart')}
                                        className={cn(css(styles.cart), 'is-clickable')}
                                    />
                                </span>
                                <div className="columns">
                                    <div className="column is-narrow">
                                        <ion-icon onClick={() => setMenu(true)} size="large" name="menu-outline" />
                                    </div>
                                    <div className="column">
                                        <Search onSearch={onSearch} />
                                    </div>

                                    <div className="column is-narrow">
                                        {path.startsWith('/products') || path.startsWith('/borrow') || path.startsWith('/users') ? (
                                            <Button
                                                text="Добавить"
                                                icon="add-outline"
                                                className="button is-info mb-2"
                                                onClick={showCreateModal} />
                                        ) : null}
                                        {path.startsWith('/borrow') ? (
                                            <Button
                                                text="Выберите"
                                                icon="send-outline"
                                                className="button is-info ml-4"
                                                onClick={onSendSelect} />
                                        ) : null}
                                        {path.startsWith('/products') ? (
                                            <Button
                                                onClick={showCreateCheck}
                                                icon="add-outline"
                                                className=" button is-info ml-4"
                                                text=" Янги чек қўшиш" />
                                        ) : null}
                                        {path.startsWith('/borrow') ? (
                                            <Button
                                                loading={sendMessage.loading}
                                                onClick={onSend}
                                                icon="paper-plane-outline"
                                                className=" button is-info ml-4"
                                                text="Всем SMS" />
                                        ) : null}
                                        {path.startsWith('/borrow') ? (
                                            <Button
                                                onClick={excel.request}
                                                icon="cloud-upload-outline"
                                                className=" button is-info ml-4"
                                                text="Ехcел" />
                                        ) : null}

                                    </div>
                                </div>
                                {children}
                            </div>
                        </div>
                        <div className={css(menu ? styles.marketBackground : null)} onClick={() => setMenu(false)} />
                    </Fragment>
                )}
            </div>

        </div>
    )
}

const styles = StyleSheet.create({
    content: {
        minHeight: '100vh',
        background: '#f2f6f7',
    },
    marketName: {
        position: 'absolute',
        marginTop: '2.8rem',
        marginLeft: '2rem',
        fontWeight: '400',
    },
    desktopSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(0)',
            transition: 'all 300ms',
        },
    },
    mobileSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(-14rem)',
            transition: 'all 300ms',
        },
    },
    showerIcon: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        width: '14.1rem',
        margin: '0',
        zIndex: 6,
        paddingTop: '10%',
        boxShadow: '0 0.5em 1em rgba(10, 10, 10, 0.1)',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 1024px)': {
            height: 'calc(100vh - 100%)',
            background: 'white',
            overflowX: 'scroll',
        },
    },
    sideMenu: {
        marginTop: '3.3rem',
        marginLeft: '0rem',
    },
    padding: {
        padding: '1.8rem',
    },
    link: {
        color: 'rgba(0, 0, 0, 0.5)',
        fontWeight: '400',
        marginBottom: '1rem',
    },
    icon: {
        margin: '0rem 1rem 0rem 2rem',
    },
    container: {
        margin: '0 0 0 14rem',
        '@media (max-width: 769px)': {
            margin: '0',
        },
    },
    logo: {
        marginTop: '6rem',
        marginLeft: '2.1rem',
        width: '8rem',
    },
    active: {
        fontWeight: '600',
        color: '#53a4c1',
        boxShadow: 'inset 4px 0px #53a4c1',
    },
    textButton: {
        background: 'gray',
    },
    hiderIcon: {
        transform: 'translate(9.6rem, -15rem)',
        color: 'white',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    text: {
        marginTop: '3px',
    },
    background: {
        '@media (max-width: 769px)': {
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            background: 'RGBA(0, 0, 0, .5)',
            zIndex: 4,
        },
    },
    marketDesktopSidebar: {
        transform: 'translateX(0)',
        transition: 'all 300ms',
    },
    marketMobileSidebar: {
        transform: 'translateX(-14rem)',
        transition: 'all 300ms',
    },
    marketShowerIcon: {
        display: 'flex',
        flexDirection: 'row',

    },
    marketSidebar: {
        minHeight: '100%',
        position: 'fixed',
        width: '14.1rem',
        margin: '0',
        zIndex: '5',
        paddingTop: '10%',
        boxShadow: '0 0.5em 1em rgba(10, 10, 10, 0.1)',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 1500px)': {
            height: 'calc(100vh - 100%)',
            background: 'white',
            overflowX: 'scroll',
        },
    },
    marketContainer: {
        margin: '0',
    },
    marketHiderIcon: {
        transform: 'translate(9.6rem, -15rem)',
        color: 'white',
    },
    marketBackground: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: 'RGBA(0, 0, 0, .5)',
        zIndex: 4,
    },
    cart: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        fontSize: '32px',
    },
    circle: {
        border: '2px solid white',
        borderRadius: '50%',
        color: 'white',
        fontSize: '14px',
        minWidth: '30px',
        height: '28px',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    circleOrders: {
        border: '2px solid white',
        borderRadius: '50%',
        color: 'white',
        fontSize: '12px',
        paddingTop: 1,
        marginTop: 4,
        marginLeft: 8,
        width: '25px',
        height: '24px',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
})

import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import Layout from '../components/Layout/Layout'
import { TERRITORY_LIST, USER_LIST } from '../urls'
import { useLoad } from '../hooks/request'
import Table from '../components/common/Table'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import UserCreate from '../components/UserCreate'
import UserItem from '../components/UserItem'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'

export default function Users() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const market = useLoad({
        url: USER_LIST,
        params: {
            ...params,
            page,
        },
    }, [params, page])
    const marketList = market.response ? market.response.results : []
    const users = useLoad({ url: USER_LIST })
    const userList = users.response ? users.response.results : []
    const territory = useLoad({ url: TERRITORY_LIST })
    const history = useHistory()

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ search })}`)
    }

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <UserCreate
            reload={market}
            onCancel={() => setShowUpdateModal()}
            userList={userList}
            territory={territory.response ? territory.response.results : []} />,
    )
    if (!cp('READ')) {
        return <PermissionDenied />
    }

    return (
        <Layout onSearch={onSearch} showCreateModal={showUpdateModal}>
            <div className={cn(css(styles.table), 'box mb-2')}>
                <div className={cn(css(styles.tableItem), '')}>

                    <Table
                        loading={market.loading}
                        items={marketList}
                        columns={{
                            name: 'Название',
                            confirmationCode: 'Название',
                            phoneNumber: 'Номер телефона',
                            user: 'Пользователь',
                            d: '',
                            actions: '',
                        }}
                        totalCount={market.response ? market.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item) => (
                            <UserItem
                                key={item.id}
                                item={item}
                                territory={territory.response ? territory.response.results : []}
                                reload={market}
                                userList={userList} />
                        )} />
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    table: {
        '@media (max-width: 500px)': {
            overflowX: 'scroll',
        },
    },
    tableItem: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
    br: {
        '@media (max-width: 500px)': {
            width: 800,
        },
    },
})

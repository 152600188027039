import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'querystring'
import cn from 'classnames'
import Layout from '../components/Layout/Layout'
import { useLoad } from '../hooks/request'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Loader from '../components/common/Loader'
import ClientCheckItem from '../components/ClientCheckItem'
import ClientTabs from '../components/ClientTabs'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'

export default function ClientOrder() {
    const params = useQueryParams()
    const history = useHistory()
    const check = useLoad({ url: '/main/order' })
    const checkItem = check.response ? check.response.results : []

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({
            ...params,
            search,
        })}`)
    }

    if (!cp('READ_CLIENT')) {
        return <PermissionDenied />
    }

    return (
        <Layout onSearch={onSearch}>
            <ClientTabs />
            {!check.loading ? (
                <div className="">
                    {check.response && check.response.count === 0 ? (
                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>
                    ) : (
                        <div className="">
                            {checkItem.map((item) => (
                                <ClientCheckItem
                                    check={check}
                                    reload={check}
                                    key={item.id}
                                    item={item}
                                />
                            ))}
                        </div>
                    )}
                    <div className=" is-flex is-justify-content-center" />
                </div>
            ) : (
                <Loader className={css(styles.loader)} large center />
            )}
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        height: 400,
    },
})

import React from 'react'
import { Form, Formik } from 'formik'
import Input from './common/Input'
import Button from './common/Button'
import { getDateOtherFormat } from '../utils/date'
import Checkbox from './common/Checkbox'

export default function BorrowClientForm({
    onSubmit,
    onCancel,
    loading,
    initialValues,
    setBorrow,
}) {
    const currentDate = new Date()
    const nextWeek = new Date()

    return (
        <Formik onSubmit={onSubmit} initialValues={{
            amount: 0,
            smsStatus: false,
            termDate: getDateOtherFormat(nextWeek.setDate(currentDate.getDate() + 7)),
            ...initialValues,
        }}>
            <Form>
                <Input
                    onChange={setBorrow}
                    type="number"
                    label="Берилгам сумма"
                    placeholder="0"
                    className=""
                    name="amount" />

                <Checkbox
                    style={{ width: 20, height: 20 }}
                    label="Берилгам сумма"
                    name="smsStatus" />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="close-outline"
                        text="Закрыть"
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text="Сохранить"
                        icon="add-outline"
                        type="submit"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}

import React from 'react'
import Layout from './Layout/Layout'

export function PermissionDenied() {
    return (
        <Layout>
            <div className="notification is-warning is-light">
                <strong>Упс!!! У вас нет доступа!!</strong>

                <h1>
                    Простите но у вас нет полномочий на просмотр данной страницы.
                    Страницы которые доступны, показаны Вам в меню.
                    Для более подробной информации обращайтесь к администратору.
                </h1>
            </div>
        </Layout>
    )
}

import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import NotFound from './pages/NotFound'
import { getEnv } from './utils/environment'
import BaseContextWrapper from './components/common/BaseContext'
import { LanguageProvider } from './contexts/LanguageContext'
import Products from './pages/Products'
import Login from './pages/Login'
import Archive from './pages/Archive'
import ProtectedRoute from './components/common/ProtectedRoute'
import Borrow from './pages/Borrow'
import Client from './pages/Client'
import ClientOrder from './pages/ClientOrder'
import Users from './pages/Users'
import LoginClient from './pages/LoginClient'
import ClientOrders from './pages/ClientOrders'
import SettingsUser from './pages/SettingsUser'
import AdminOrders from './pages/AdminOrders'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
    }, [])

    return (
        <LanguageProvider>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/" component={Login} exact />
                        <Route path="/login" component={Login} exact />
                        <Route path="/client-login" component={LoginClient} exact />
                        <ProtectedRoute path="/products" component={Products} exact />
                        <ProtectedRoute path="/client" component={Client} exact />
                        <ProtectedRoute path="/users" component={Users} exact />
                        <ProtectedRoute path="/order" component={ClientOrder} exact />
                        <ProtectedRoute path="/archive_order" component={ClientOrders} exact />
                        <ProtectedRoute path="/cart" component={Archive} exact />
                        <ProtectedRoute path="/archive" component={Archive} exact />
                        <ProtectedRoute path="/borrow" component={Borrow} exact />
                        <ProtectedRoute path="/checks" component={AdminOrders} exact />
                        <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </LanguageProvider>
    )
}

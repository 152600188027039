import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { ORDER_DETAIL, SEND_TO_TELEGRAM } from '../urls'
import { useDeleteRequest, useGetRequest, usePutRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { format } from '../utils/number'
import CheckProductItem from './CheckProductItem'
import CheckView from './CheckView'
import ComponentToPrint from './Print'
import Button from './common/Button'
import CheckUpdateCustom from './CheckUpdateCustom'

export default function CheckItem({ reload, item, check }) {
    const checkRemove = useDeleteRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    async function onDeleteCheck() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await checkRemove.request()
            await reload.request()
        }
    }
    // eslint-disable-next-line max-len,no-return-assign
    const result = item.orderProduct.reduce((total, currentValue) => total + parseInt(currentValue?.product.priceSale * currentValue.count, 10), 0)

    const [showCheckView, hideCheckView] = useModal(
        <CheckView
            order={item}
            onCancel={() => hideCheckView()}
            item={item.orderProduct}
        />,
    )

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const updateOrder = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })
    async function onSubmit(data) {
        const { success } = await updateOrder.request({
            data: {
                ...data,
                status: 'archive',
                items: item.orderProduct,
                client: item.client.id,
            },
        })
        if (success) {
            reload.request()
        }
    }

    const send = useGetRequest({ url: SEND_TO_TELEGRAM.replace('{orderId}', item.id) })
    return (

        <div className="card mb-4">
            <header className="card-header">
                <CheckUpdateCustom reload={reload} item={item} />
                <Button
                    onClick={onDeleteCheck}
                    className="button is-danger is-pulled-center is-outlined mr-1 mt-4 ml-3"
                    icon="trash" />
                <Button
                    loading={send.loading}
                    onClick={send.request}
                    className="button is-link is-pulled-center is-outlined mt-4"
                    icon="paper-plane" />
            </header>

            <div style={{ overflowX: 'scroll' }} className="card-content">
                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalized">
                    {item.orderProduct.map((element) => (
                        <CheckProductItem check={check} reload={reload} element={element} />

                    ))}
                    <td colSpan={7}>
                        <b>
                            <span className="is-justify-content-center is-pulled-right">
                                <p className="is-size-5">Общий: {format(result)} СЎМ</p>
                                <p className="is-size-5">Долг: {format(item?.client.sumAmount)} СЎМ</p>
                            </span>
                        </b>
                    </td>
                </table>
            </div>

            <footer className="card-footer">
                <p onClick={showCheckView} className="card-footer-item is-bold">
                    <b>Қарзга ёзиш</b>
                </p>
                <p onClick={() => onSubmit()} className="card-footer-item">
                    <b>Aрхив</b>
                </p>
                <p onClick={handlePrint} className="card-footer-item"><b>Принт</b></p>
                <div style={{ display: 'none' }}>
                    <ComponentToPrint result={result} item={item} ref={componentRef} />
                </div>
            </footer>

        </div>
    )
}

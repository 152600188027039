import React from 'react'
import { usePostRequest } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import ProductForm from './ProductForm'

export default function ProductCreate({ onCancel, categoryList, reload, brandList }) {
    const products = usePostRequest({ url: PRODUCT_LIST })

    async function onSubmit(data) {
        const { success } = await products.request({ data })

        if (success) {
            reload.request()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Добавить товар</b></h1>

            <ProductForm
                loading={products.loading}
                categoryList={categoryList}
                brandList={brandList}
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>
    )
}

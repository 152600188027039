import React from 'react'
import { useModal } from '../hooks/modal'
// import ProductUpdate from './ProductUpdate'
import ProductDetailModal from './ProductDetailModal'
import { format } from '../utils/number'
// import { measurement } from '../utils/position'

export default function ClientProductItem({
    // reload,
    check,
    // categoryList,
    item,
    user,
    // brandList,
    // products,
}) {
    const [showDetailModal, hideDetailModal] = useModal(
        <ProductDetailModal
            check={check}
            orderId={item.id}
            user={user}
            item={item}
            hideModal={() => hideDetailModal()}
        />,
    )

    // const productRemove = useDeleteRequest({ url: PRODUCT_DETAIL.replace('{id}', item.id) })
    /*
        async function onDelete() {
            if (global.confirm('Вы действительно хотите удалить?')) {
                await productRemove.request()
                await reload.request()
            }
        }

        const [showUpdateModal, setShowUpdateModal] = useModal(
            <ProductUpdate
                products={products}
                categoryList={categoryList}
                brandList={brandList}
                item={item}
                hideModal={() => setShowUpdateModal()}
            />,
        ) */

    return (
        <tr className="is-capitalized" key={item.id}>
            <td onClick={() => showDetailModal()} className="is-size-5">{item.name}</td>
            <td className="is-size-5">{format(item.priceSale)} {/* <h5 className="is-size-6">uzs</h5> */}</td>
            {/* <td className="is-size-5">{measurement(item.measurement)}</td> */}
            {/* <td className="has-text-right is-flex">
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 button is-success"
                    icon="pencil-outline" />
                <Button
                    onClick={onDelete}
                    className="button is-danger"
                    icon="trash" />

            </td> */}
        </tr>
    )
}

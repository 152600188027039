import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export default function AdminTabs() {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': path.startsWith('/products') })}>
                    <NavLink to="/products">Продукты</NavLink>
                </li>
                <li className={cn({ 'is-active': path.startsWith('/checks') })}>
                    <NavLink to="/checks">Чеки</NavLink>
                </li>
                {/* <li className={cn({ 'is-active': path.startsWith('/archive_order') })}>
                    <NavLink to="/archive_order">Bсе чеки </NavLink>
                </li> */}
            </ul>
        </div>
    )
}

import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { measurements } from '../utils/types'

export default function ProductForm({ onCancel, onSubmit, initialValues, loading }) {
    function handleSubmit(data) {
        const newData = new FormData()
        newData.append('name', data.name)
        newData.append('priceSale', data.priceSale)
        newData.append('price', data.price)
        newData.append('measurement', data.measurement)
        onSubmit(newData)
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={{
                name: '',
                priceSale: '',
                price: '',
                measurement: '',

                ...initialValues,
            }}>
            <Form>
                <Input
                    name="name"
                    label="Имя"
                    validate={required} />

                <Input
                    name="priceSale"
                    type="number"
                    label="Цена продажи"
                    validate={required} />
                <Input
                    name="price"
                    type="number"
                    label="Цена покупки"
                    validate={required} />

                <Select
                    empty
                    name="measurement"
                    label="Измерение"
                    optionValue="value"
                    optionLabel="name"
                    options={measurements}
                    validate={required} />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="close-outline"
                        text="Закрыть"
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        loading={loading}
                        text="Сохранить"
                        icon="add-outline"
                        type="submit"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}

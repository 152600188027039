import React from 'react'
import { STATUS_UPDATE } from '../urls'
import { useGetRequest } from '../hooks/request'
import { format } from '../utils/number'
import Button from './common/Button'
import ClientCheckProductItem from './ClientCheckProductItem'

export default function ClientCheckItem({
    reload,
    item,
    check,
}) {
    const update = useGetRequest({ url: STATUS_UPDATE.replace('{orderId}', item.id) })
    // eslint-disable-next-line max-len,no-return-assign
    const result = item.orderProduct.reduce((total, currentValue) => total + parseInt(currentValue?.product.priceSale * currentValue.count, 10), 0)
    return (
        <div className="">
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        {item.client.name} ({item.client.phoneNumber})
                    </p>
                    <button className="card-header-icon" aria-label="more options">
                        <span className="icon">
                            <i className="fas fa-angle-down" aria-hidden="true" />
                        </span>
                    </button>
                </header>

                <div style={{ overflowX: 'scroll' }} className="mb-2 card-content">
                    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-capitalized">
                        {item.orderProduct.map((element) => (
                            <ClientCheckProductItem check={check} reload={reload} element={element} />

                        ))}
                        <td colSpan={7}>
                            <b>
                                <span className="is-flex is-justify-content-center">
                                    <p className="is-size-5 pt-2 pb-2">Жами: {format(result)} СЎМ</p>
                                </span>
                            </b>
                        </td>
                    </table>

                </div>
            </div>

            <Button
                loading={update.loading}
                style={{ width: '100%' }}
                text="Заказать"
                onClick={update.request}
                className="button is-link is-medium"
                icon="add" />

        </div>
    )
}

import React from 'react'
import Button from './common/Button'
import { getUserType } from '../utils/types'

export default function UserItem({
    item,
}) {
    return (
        <tr key={item.id}>
            <td className="is-size-5">{item.username}</td>
            <td className="is-size-5">{item.phoneConfirmationCode}</td>
            <td className="is-size-5">{item.phoneNumber}</td>
            <td className="is-size-5">{item.userType ? getUserType(item.userType) : ''}</td>
            <td className="is-size-5">{item.createdAt}</td>
            {/* <td className="is-size-5">{item?.user.firstName} {item?.user.lastName}</td> */}
            <td className="has-text-right">
                <Button
                    text="Удалить"
                    className="is-small is-danger mr-4"
                    icon="trash" />
            </td>
        </tr>
    )
}

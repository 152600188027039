import React from 'react'
import { PRODUCT_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ProductForm from './ProductForm'

export default function ProductUpdate({ hideModal, item, brandList, products }) {
    const productUpdate = usePutRequest({ url: PRODUCT_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await productUpdate.request({ data })
        if (success) {
            products.request()
            hideModal()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить товар</b></h1>

            <ProductForm
                onCancel={hideModal}
                brandList={brandList}
                initialValues={{
                    ...item,
                }}
                onSubmit={onSubmit} />
        </div>
    )
}

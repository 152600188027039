import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'querystring'
import cn from 'classnames'
import Layout from '../components/Layout/Layout'
import { useLoad } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Table from '../components/common/Table'
import ClientProductItem from '../components/ClientProductItem'
import ClientTabs from '../components/ClientTabs'
import { checkPermission as cp } from '../utils/auth'
import { PermissionDenied } from '../components/PermissionDenied'

export default function Client() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const products = useLoad({
        url: PRODUCT_LIST,
        params: { page, ...params },
    }, [params, page])
    const productList = products.response ? products.response.results : []

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    if (!cp('READ_CLIENT')) {
        return <PermissionDenied />
    }

    return (
        <Layout onSearch={onSearch}>
            <ClientTabs />
            <div className="is-flex columns">
                <div className="column">
                    {products.response && products.response.count === 0 ? (
                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>
                    ) : (
                        <div className="box">
                            <Table
                                loading={products.loading}
                                items={productList}
                                columns={{
                                    name: 'Название',
                                    price: 'Цена',
                                }}
                                totalCount={products.response ? products.response.count : 0}
                                pageSize={15}
                                activePage={page}
                                onPageChange={setPage}
                                renderItem={(item) => (
                                    <ClientProductItem
                                        products={products}
                                        key={item.id}
                                        item={item}

                                    />
                                )} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        height: 400,
    },
})
